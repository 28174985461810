
  import userController from '/home/builduser/work/7c2ba04858947e18/packages/loyalty-tpa/src/components/LoyaltyStatus/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://e07fe3370f1a46248e188b385346a729@sentry.wixpress.com/1484',
    id: 'e07fe3370f1a46248e188b385346a729',
    projectName: 'loyalty-tpa',
    teamName: 'pricing-plans',
    errorMonitor: true,
  };

  var experimentsConfig = {"centralized":true,"scopes":["loyalty-tpa"]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7c2ba04858947e18/packages/loyalty-tpa/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"status-widget.settings.tabs.main":"Main","status-widget.settings.tabs.design":"Design","status-widget.settings.tabs.text":"Text","status-widget.settings.tabs.display":"Display","status-widget.settings.tabs.layout":"Layout","status-widget.settings.main-tab.title":"Customize Loyalty","status-widget.settings.main-tab.subtitle":"Customize your loyalty widget to help your customers discover the program, track their points, and easily redeem them for rewards.","status-widget.settings.main-tab.button":"Customize","status-widget.settings.design-tab.background-color-and-opacity":"Background color & opacity","status-widget.settings.design-tab.text-font-color":"Font & color","status-widget.settings.design-tab.text-color":"Text color","status-widget.settings.design-tab.border-color-and-opacity":"Border color & opacity","status-widget.settings.design-tab.corner-radius":"Corner radius","status-widget.settings.design-tab.border-width":"Border width","status-widget.settings.design-tab.reset-button":"Reset to original design","status-widget.settings.design-tab.choose-your-font":"Choose your font","status-widget.settings.design-tab.regular-tab":"Regular","status-widget.settings.design-tab.hover-tab":"Hover","status-widget.settings.bp.banner.desktop-and-tablet":"Changes apply to desktop and tablet.","status-widget.settings.bp.banner.cascades":"Changes will cascade to lower breakpoints.","status-widget.settings.bp.banner.all":"Changes apply to all breakpoints.","status-widget.settings.bp.banner.mobile":"Changes apply to mobile only.","status-widget.settings.bp.learn-more":"Learn more","status-widget.settings.bp.learn-more.link":"https://support.wix.com/en/article/working-with-breakpoints"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/7c2ba04858947e18/packages/loyalty-tpa/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Loyalty",
    componentName: "LoyaltyStatus",
    appDefinitionId: "553c79f3-5625-4f38-b14b-ef7c0d1e87df",
    componentId: "f30e2cf0-de22-453e-ac3b-973c84cd4311",
    projectName: "loyalty-tpa",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "components/LoyaltyStatus/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/7c2ba04858947e18/packages/loyalty-tpa/src/components/LoyaltyStatus/controller.ts",
  };

  export const controller = _controller
  export default controller;
